import React from 'react';
import GradualSpacing from '../magicui/gradual-spacing';
import { Link as ScrollLink } from 'react-scroll';
import GradualButton from '../magicui/gradual-button';
import { useTranslation } from 'react-i18next';

const Hero = () => {

  const { t } = useTranslation();

  return (
    <div id="Home" className="text-black bg-gradient-to-r from-white via-blue-400 to-red-500 bg-[length:200%_200%] animate-gradient-move">
      <div className="sm:max-w-[1000px] md:max-w-[1100px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center items-center">
        <GradualSpacing
          className="font-display text-3xl lg:text-6xl sm:text-4xl font-bold tracking-[-0.1em] text-black dark:text-black md:text-5xl md:leading-[5rem]"
          text={t('title')}
        />
        <ScrollLink to="Services" smooth={true} duration={500} className="mt-6">
          <GradualButton
              text={t('buttonHero')}
              hoverText="↓"
              className="w-[190px] sm:w-[300px] text-xs sm:text-sm font-bold py-4 sm:py-5 border-2 border-black text-black bg-white rounded-md transition duration-200 hover:bg-black hover:text-white"
          />
      </ScrollLink>
      </div>
    </div>
  );
};

export default Hero